<template>
  <div>
    <div>      
      <b-card title="">
        <div class="mb-1 text-start">
          <router-link
            :to="`/`"
            class="link-none"
          >
            <feather-icon icon="ArrowLeftIcon" size="20" class="" />
          </router-link>
        </div>
        <p>Eventos:</p>
        <b-form action="">          
          <div class="d-flex justify-content-end mb-2">
            <b-form-input
              v-model="searchValue"
              class="d-inline-block mr-1"
              placeholder="Filtrar..."
              @keyup="filtrarEventos"
            />
          </div>
        </b-form>
        <div class="grid">
          <div class="grid-item img-event d-flex flex-column justify-content-end" style="max-width: 400px;" v-for="evento in eventos" :key="evento.id">
            <b-link @click="tryToGetIn(evento.id)">
              <img
                style=" max-width: 400px"
                class="rounded w-100 "
                :src="getCardUrl(evento.banner)"
                :alt="evento.title"
              />
              <h3>{{ evento.title }}</h3>
            </b-link>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import services from './eventsServices.js'
import { BCard, BRow, BCol, BTable, BFormInput, } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
export default {
  
  components: {
  },
  setup() {
  },
  data() {
    return {
      imagePath: require("@/assets/default/cards/eventov2.png"), // Use require para importar a imagem
      eventos: [],
      lives: [],
      BaseUrl: process.env.VUE_APP_API_BASE,
      searchValue: null,
      token: localStorage.getItem("accessToken"),
    };
  },
  beforeMount() {
    this.fetchEventos();
  },
  created() {
  },
  watch: {
  },
  methods: {
    getCardUrl(cardImage) {
      const imagePath = cardImage
        ? `${process.env.VUE_APP_API_BASE}/storage/images/event_banner/${cardImage}`
        : this.imagePath;

      return imagePath;
      },
    filtrarEventos() {
      if (this.searchValue && this.searchValue.trim() !== '') {
        const termoPesquisa = this.searchValue.trim().toLowerCase();
        this.eventos = this.eventos.filter(evento => evento.title.toLowerCase().includes(termoPesquisa));
      } else {
        this.fetchEventos();
      }
    },
    fetchEventos() {
      const apiUrl = process.env.VUE_APP_API + "/events/get-events";
      axios
        .get(apiUrl)
        .then((response) => {
          this.eventos = response.data.events;
        })
        .catch((error) => {
          console.error("Error fetching eventos:", error);
        });
    },
    tryToGetIn(event_id) {

      const axiosConfig = {
        headers: {
          'Authorization': 'Bearer ' + this.token,
        }
      };
      
      const apiUrl = process.env.VUE_APP_API + `/events/check-permission/${event_id}`;

      let status_permission = null

      axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        this.lives = response.data.response;
        
        status_permission = response.data.status
        //return console.log(status_permission)
        if (status_permission === null) {
          this.$swal({
            title: 'Deseja solicitar participação no evento?',
            text: 'Você ainda não possui autoriração!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b65f0',
            cancelButtonColor: '#c1c1c1',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim, desejo solicitar!',
            showLoaderOnConfirm: true,
            preConfirm: () => axios.get(`${process.env.VUE_APP_API}/events/try-to-get-in/${event_id}`, axiosConfig)
                .then(response => {
                  console.log(response.data)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Solicitação enviada!',
                      icon: 'InfoIcon',
                      variant: 'success',
                    }
                  })
                  this.$emit('close')
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      icon: 'InfoIcon',
                      title: error.message,
                      variant: 'danger',
                    }
                  })
                }),
            allowOutsideClick: () => !this.$swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire(
                'Você solicitou participação neste evento!',
                'Por favor, aguarda a aprovação de um dos nossos administradores!',
                'success'
              )
            }
          })
        } else if (status_permission === 'wait') {
          this.$swal({
            title: 'Você já solicitou participação no evento!',
            text: 'Aguarde até que um administrador autorize sua entrada.',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: false,
            confirmButtonColor: '#3b65f0',
            cancelButtonColor: '#3b65f0',
            cancelButtonText: 'Entendi',
            showLoaderOnConfirm: true,
          })
          console.log(status_permission)
        } else if (status_permission === 'aproved') {
          this.$router.push({ name: 'events_lives', params: { id: event_id }})
        }
      })
    },
  }
}
</script>
<style scoped>
.img-event {
  border-color: #161D31 !important;
  border-width: 2px !important;
  margin-bottom: 10px;
}

.link-none {
  text-decoration: none !important;
  color: inherit;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.bg-dark-blue {
  background-color: #161d31 !important;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>